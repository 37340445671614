import { Fade } from "@/components/animation";
import { Center, Heading, VStack } from "@msuite/picasso";
import { AnimatePresence } from "framer-motion";
import type { FC } from "react";
import { IoHelp } from "react-icons/io5";

/** Props Interface */
interface BlankSlateProps {
	heading?: string;
	subtext?: string;
	minH?: string;
	icon?: React.ReactElement;
	withBorder?: boolean;
}

export const BlankSlate: FC<BlankSlateProps> = ({ heading, subtext, minH, icon, withBorder }) => {
	/** Render */
	return (
		<AnimatePresence mode="wait">
			<Center
				userSelect="none"
				w="100%"
				h="100%"
				overflow="hidden"
				py="3rem"
				minH={minH}
				borderWidth={withBorder ? 1 : undefined}
				borderStyle="dashed"
				rounded="lg"
			>
				<VStack spacing={3}>
					<Heading
						size="lg"
						cursor="pointer"
						color="gray.600"
					>
						<Fade
							delay={0.5}
							duration={0.5}
							key="icon"
							renderKey="icon"
						>
							{icon}
						</Fade>
					</Heading>

					<AnimatePresence mode="wait">
						<Fade
							key="information-1"
							delay={0.25}
							duration={0.5}
							renderKey="information-1"
						>
							<Heading
								size="sm"
								textAlign="center"
							>
								{heading}
							</Heading>
						</Fade>

						<Fade
							key="information-2"
							delay={0.75}
							duration={0.5}
							renderKey="information-2"
						>
							<Heading
								size="xs"
								fontWeight="medium"
								color="gray.500"
								textAlign="center"
							>
								{subtext}
							</Heading>
						</Fade>
					</AnimatePresence>
				</VStack>
			</Center>
		</AnimatePresence>
	);
};

/** Default Props */
BlankSlate.defaultProps = {
	heading: "Keine Daten vorhanden",
	subtext: "Es konnten keine Daten gefunden werden",
	minH: "31rem",
	icon: <IoHelp size={26} />,
};
