import { SurveyContext } from "@/context/survey";
import { ax } from "@/core";
import { Button, HStack, Heading, IconButton, Text, VStack, useModalContext, useQueryClient } from "@msuite/picasso";
import { type FC, useContext } from "react";
import { IoArrowBack } from "react-icons/io5";

export const EndPage: FC = () => {
	/** Context */
	const { survey, surveyPath, response, workspaceId, jumpToPreviousQuestion } = useContext(SurveyContext);
	const { onClose } = useModalContext();
	const queryClient = useQueryClient();

	/** Handlers */
	const handleSubmitSurvey = async () => {
		await ax.patch(`/survey/${survey?.id}/workspace/${workspaceId}/response/${response?.id}/submit`, {
			data: { surveyPath },
		});
		onClose();
		queryClient.invalidateQueries(["surveys"]);
	};

	/** Render */
	return (
		<VStack
			position="absolute"
			left="0"
			right="0"
			top="0"
			bottom="0"
			justify="center"
			align="center"
			px="10vw"
			spacing="10vh"
		>
			<VStack
				align="stretch"
				width="100%"
			>
				<Heading>{survey?.endPage?.title}</Heading>
				<Text
					fontSize="sm"
					fontWeight="medium"
					color="gray.700"
				>
					{survey?.endPage?.description}
				</Text>
			</VStack>
			<HStack
				justify="center"
				width="100%"
				columnGap={4}
			>
				<IconButton
					icon={<IoArrowBack />}
					colorScheme="gray"
					aria-label="Zurück"
					onClick={jumpToPreviousQuestion}
				/>
				<Button
					colorScheme="brand"
					onClick={handleSubmitSurvey}
				>
					Umfrage abschließen
				</Button>
			</HStack>
		</VStack>
	);
};
