import type { ISurvey } from "@msuite/katana";
import { createContext } from "react";

/** Types */
export type HandleSurveysModalViewState = "overview" | "survey";

/** Interface */
interface IHandleSurveyModalContext {
	viewState: HandleSurveysModalViewState;
	surveys: ISurvey[] | undefined;
	selectedSurvey: ISurvey | undefined;
	setSelectedSurvey: (survey: ISurvey | undefined) => void;
}

/** Context */
export const HandleSurveyModalContext = createContext<IHandleSurveyModalContext>({
	viewState: "overview",
	surveys: [],
	selectedSurvey: undefined,
	setSelectedSurvey: () => {},
});
