import { SurveyContext } from "@/context/survey";
import { Button, HStack, IconButton } from "@msuite/picasso";
import { type FC, useContext, useState } from "react";
import { IoArrowBack } from "react-icons/io5";

/** Props Interface */
interface QuestionStepperProps {
	onUpdate: () => Promise<void>;
	forwardDisabled?: boolean;
	isSkippable?: boolean;
}

export const QuestionStepper: FC<QuestionStepperProps> = ({ onUpdate, forwardDisabled, isSkippable }) => {
	/** State */
	const [backIsLoading, setBackIsLoading] = useState<boolean>(false);
	const [forwardIsLoading, setForwardIsLoading] = useState<boolean>(false);

	/** Context */
	const { jumpToNextQuestion, jumpToPreviousQuestion } = useContext(SurveyContext);

	/** Render */
	return (
		<HStack
			gap={6}
			justifyContent="flex-end"
		>
			<IconButton
				isLoading={backIsLoading}
				isDisabled={forwardIsLoading}
				aria-label="label"
				icon={<IoArrowBack />}
				onClick={async () => {
					setBackIsLoading(true);
					await onUpdate();
					jumpToPreviousQuestion();
					setBackIsLoading(false);
				}}
			/>
			<Button
				isLoading={forwardIsLoading}
				isDisabled={backIsLoading || forwardDisabled}
				colorScheme="brand"
				onClick={async () => {
					setForwardIsLoading(true);
					await onUpdate();
					jumpToNextQuestion();
					setForwardIsLoading(false);
				}}
			>
				{isSkippable ? "Überspringen" : "Weiter"}
			</Button>
		</HStack>
	);
};
