import { ax } from "@/core";
import { DASHBOARD_INTERNAL_SUBCONTRACTOR_LAYOUT_ID } from "@/sites/home/use-dashboard";
import type { DashboardSubcontractorsInternal, SubcontractorPublishEntry } from "@msuite/katana";
import { Modal, ModalCloseButton, ModalContentContainer, ModalOverlay, useApi } from "@msuite/picasso";
import { type FC, useState } from "react";
import { Content } from "./content";
import { ProcessTimesheetsModalContext } from "./context";

/** Props Interface */
interface ProcessTimesheetsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const ProcessTimesheetsModal: FC<ProcessTimesheetsModalProps> = ({ isOpen, onClose }) => {
	/** State */
	const [selectedPublishEntry, setSelectedPublishEntry] = useState<
		| {
				entry: SubcontractorPublishEntry;
				constructionSiteId: string;
				subcontractorId: string;
		  }
		| undefined
	>();

	/** Hooks */
	const { data, refetch } = useApi<DashboardSubcontractorsInternal>(ax, {
		url: "/v2/dashboard/timesheets",
		dependencies: ["dashboard", "timesheets"],
	});

	/** Render */
	return (
		<ProcessTimesheetsModalContext.Provider
			value={{
				refetch,
				timesheetSections: data?.timesheetsUncontrolledSections ?? [],
				selectedPublishEntry,
				setSelectedPublishEntry,
			}}
		>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContentContainer
					width="100rem"
					layoutId={DASHBOARD_INTERNAL_SUBCONTRACTOR_LAYOUT_ID}
				>
					<ModalCloseButton />
					<Content />
				</ModalContentContainer>
			</Modal>
		</ProcessTimesheetsModalContext.Provider>
	);
};
