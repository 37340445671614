import { motion } from "framer-motion";
import type { FC } from "react";

/** Props Interface */
interface AppearProps {
	avoidIntialAnimation?: boolean;
	children: React.ReactNode;
	delay?: number;
	renderKey?: string;
	width?: string;
	height?: string;
	ignoreSizing?: boolean;
	ignoreAppear?: boolean;
	ignoreExit?: boolean;
	style?: React.CSSProperties;
}

export const Appear: FC<AppearProps> = ({
	children,
	delay,
	renderKey,
	avoidIntialAnimation,
	width,
	height,
	ignoreSizing,
	ignoreAppear,
	ignoreExit,
	style,
}) => {
	/** Render */
	return (
		<motion.div
			initial={ignoreAppear ? undefined : { y: avoidIntialAnimation ? 0 : 20, opacity: 0 }}
			animate={{ y: 0, opacity: 1 }}
			exit={ignoreExit ? undefined : { y: 0, opacity: 0 }}
			transition={{ delay }}
			style={{
				...(style ?? {}),
				...(ignoreSizing ? undefined : { width, height }),
			}}
			key={renderKey}
		>
			{children}
		</motion.div>
	);
};

/** Default Props */
Appear.defaultProps = {
	renderKey: undefined,
	avoidIntialAnimation: false,
	width: "100%",
	height: "100%",
	ignoreSizing: false,
	ignoreAppear: false,
	ignoreExit: false,
};
