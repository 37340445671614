import { SurveyContext } from "@/context/survey";
import { Button, HStack, Heading, Text, VStack, useColorModeValue } from "@msuite/picasso";
import { type FC, useContext } from "react";

export const StartPage: FC = () => {
	/** Context */
	const { survey, setActiveElement } = useContext(SurveyContext);

	const descriptionColor = useColorModeValue("gray.700", "gray.400");

	/** Handlers */
	const handleStartServey = () => {
		const firstQuestion = survey?.sections?.[0].questions?.[0];
		setActiveElement({
			type: "question",
			questionId: firstQuestion?.id,
			sectionId: survey?.sections?.[0].id,
			questionType: firstQuestion?.type,
		});
	};

	/** Render */
	return (
		<VStack
			position="absolute"
			left="0"
			right="0"
			top="0"
			bottom="0"
			justify="center"
			align="center"
			px="10vw"
			spacing="10vh"
		>
			<VStack
				align="stretch"
				width="100%"
			>
				<Heading size="lg">{survey?.startPage?.title}</Heading>
				<Text
					fontSize="md"
					fontWeight="medium"
					color={descriptionColor}
				>
					{survey?.startPage?.description}
				</Text>
			</VStack>
			<HStack
				justify="center"
				width="100%"
			>
				<Button
					colorScheme="brand"
					onClick={handleStartServey}
				>
					Umfrage starten
				</Button>
			</HStack>
		</VStack>
	);
};
