import { Center, Heading, useUIContext, VStack } from "@msuite/picasso";
import type { FC } from "react";
import { IoHelp } from "react-icons/io5";

/** Props Interface */
interface BlankSlateMinimalProps {
	heading?: string;
	subtext?: string;
	minH?: string;
	icon?: React.ReactElement;
	withBorder?: boolean;
}

export const BlankSlateMinimal: FC<BlankSlateMinimalProps> = ({ heading, subtext, minH, withBorder }) => {
	/** Context */
	const { colors } = useUIContext();

	/** Render */
	return (
		<Center
			w="100%"
			h="100%"
			overflow="hidden"
			py="2rem"
			minH={minH}
			borderWidth={withBorder ? 1 : 0}
			backgroundColor={colors.whiteAdjusted}
			rounded="xl"
		>
			<VStack spacing={1}>
				<Heading
					size="sm"
					textAlign="center"
				>
					{heading}
				</Heading>

				<Heading
					size="xs"
					fontWeight="medium"
					color="gray.500"
					textAlign="center"
				>
					{subtext}
				</Heading>
			</VStack>
		</Center>
	);
};

/** Default Props */
BlankSlateMinimal.defaultProps = {
	heading: "Keine Daten vorhanden",
	subtext: "Es konnten keine Daten gefunden werden",
	minH: "1rem",
	icon: <IoHelp size={26} />,
};
