import { SurveyContext } from "@/context/survey";
import { Box, HStack, useColorModeValue } from "@msuite/picasso";
import { type FC, useContext } from "react";

export const ProgressBar: FC = () => {
	/** Context */
	const { progress } = useContext(SurveyContext);

	const progressBackground = useColorModeValue("blackAlpha.100", "whiteAlpha.100");

	/** Render */
	return (
		<HStack
			height="0.5rem"
			width="100%"
			bg={progressBackground}
			rounded="lg"
			overflow="hidden"
		>
			<Box
				bg="brand.500"
				transition=".5s ease-in-out"
				width={`${progress}%`}
				height="100%"
			/>
		</HStack>
	);
};
