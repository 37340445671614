import { motion } from "framer-motion";
import type { FC } from "react";

/** Props Interface */
interface FadeProps {
	children: React.ReactNode;
	delay?: number;
	renderKey?: string | number;
	duration?: number;
	ignoreSizing?: boolean;
}

export const Fade: FC<FadeProps> = ({ children, delay, renderKey, duration, ignoreSizing }) => {
	/** Render */
	return (
		<motion.div
			key={renderKey}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ delay, duration }}
			style={ignoreSizing ? undefined : { width: "100%", height: "100%" }}
		>
			{children}
		</motion.div>
	);
};

/** Default Props */
Fade.defaultProps = {
	renderKey: "",
	duration: 1,
};
